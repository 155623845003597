import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import RedButton from 'components/buttons/RedButton';
import { H4 } from 'components/typography/Headers';
import {
  AddCampaignContextProps,
  AddCampaignProvider,
  useAddCampaign,
} from 'features/add-campaign/context/AddCampaignProvider';

import CampaignSummaryForm from './components/CampaignSummaryForm';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 80vh;
  width: 535px;

  ${theme.mq.phone} {
    width: 100%;
  }
`;

const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow-y: auto;
  padding: 0 30px;
  scrollbar-gutter: stable both-edges;

  ${theme.mq.phone} {
    padding: 0 10px;
  }
`;

const SaveButton = styled(RedButton)`
  height: 46px;
  width: unset;
  margin: 36px 40px 0 40px;

  ${theme.mq.phone} {
    margin: 36px 20px 0 20px;
  }
`;

const EditCampaignForm = () => {
  const { t } = useTranslation('addCampaign');
  const { save, isFormValid, isSaving } = useAddCampaign();

  return (
    <Container data-testid={'edit-campaign-form'}>
      <H4 data-testid={'form-header'}>{t('edit-form.header')}</H4>
      <ContentWrapper>
        <CampaignSummaryForm />
      </ContentWrapper>
      {!isSaving && (
        <SaveButton onClick={save} disabled={!isFormValid}>
          {t('common:save')}
        </SaveButton>
      )}
    </Container>
  );
};

const EditCampaignFormWithContext = ({ campaign, onCampaignSaved }: AddCampaignContextProps) => {
  return (
    <AddCampaignProvider campaign={campaign} onCampaignSaved={onCampaignSaved}>
      <EditCampaignForm />
    </AddCampaignProvider>
  );
};

export default EditCampaignFormWithContext;
