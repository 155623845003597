import Select, { components, StylesConfig } from 'react-select';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';

import BaseInputField, {
  BaseInputProps,
  containerStyle,
  fontStyle,
  inputStyle,
  placeholderStyle,
} from './BaseInputField';
import Checkbox from './Checkbox';

const StyledSelect = styled(Select)<{ $error: boolean; $success: boolean }>`
  ${containerStyle};
  ${fontStyle};
  padding: 0;
  text-align: start;

  & .dropdown-field__control {
    ${inputStyle};
    min-height: 45px;
    height: auto;
  }

  &::placeholder {
    ${placeholderStyle}
  }
`;

interface Option {
  value: string;
  label: string;
}

interface Props extends Omit<BaseInputProps, 'value' | 'onChange'> {
  options: Option[];
  values: string[];
  onChange: (values: string[]) => void;
}

const styles: StylesConfig = {
  menuPortal: base => ({ ...base, zIndex: theme.zIndex.foremost }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isFocused ? theme.color.silver : isSelected ? theme.color.white : base.backgroundColor,
  }),
};

const CustomOption = (props: any) => {
  const { data, isSelected } = props;
  return (
    <components.Option {...props}>
      <Checkbox label={data.label} checked={isSelected} />
    </components.Option>
  );
};

const MultiDropdownField = (props: Props) => {
  const { values, options, placeholder, errorMessage, successMessage, onChange } = props;
  const { className, testId, disabled } = props;

  const handleChange = (newValue?: any) => {
    const selectedOptions = newValue as Option[];
    onChange(selectedOptions ? selectedOptions.map(option => option.value) : []);
  };

  return (
    <BaseInputField testId={testId} className={className} successMessage={successMessage} errorMessage={errorMessage}>
      <StyledSelect
        className='dropdown-field'
        classNamePrefix='dropdown-field'
        menuPosition={'fixed'}
        menuPortalTarget={document.body}
        value={options.filter(option => values.includes(option.value))}
        placeholder={placeholder}
        options={options}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        components={{ Option: CustomOption }}
        isSearchable={true}
        isMulti={true}
        onChange={handleChange}
        $error={!!errorMessage}
        $success={!!successMessage}
        isDisabled={disabled}
        styles={styles}
      />
    </BaseInputField>
  );
};

export default MultiDropdownField;
