import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import checkedIcon from 'assets/svg/checked-icon.svg';

const Input = styled.input`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  appearance: none;
  border-radius: 6px;
  background-color: ${theme.color.white};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${theme.color.darkGray};
  cursor: pointer;

  &:checked {
    background-color: ${theme.color.red};
    box-shadow: 0 0 0 1px white inset;
    position: relative;
  }

  &:checked::after {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    background-image: url(${checkedIcon});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  color: ${theme.color.lightBlack};
`;

interface Props {
  checked?: boolean;
  onChange?: (value: boolean) => void;
  label?: string;
  testId?: string;
  className?: string;
}

const Checkbox = (props: Props) => {
  const { checked, label, onChange, testId, className } = props;
  return (
    <Label data-testid={testId} className={className}>
      <Input type='checkbox' checked={checked} onChange={value => onChange?.(value.target.checked)} />
      {label}
    </Label>
  );
};

export default Checkbox;
