import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { usePopup } from 'hooks/usePopup';

import CampaignErrorPopup from './CampaignErrorPopup';

export const useCampaignError = () => {
  const { showPopup, hidePopup } = usePopup();
  const { t } = useTranslation('addCampaign');
  const navigate = useNavigate();

  const handleContactSupport = () => {
    hidePopup(true);
    navigate('/messenger/context/SUPPORT');
  };

  const showAddCampaignErrorPopup = () => {
    showPopup(<CampaignErrorPopup message={t('error.unable-to-list')} onContactPress={handleContactSupport} />, {
      keepPopups: true,
    });
  };

  const showCloseCampaignErrorPopup = () => {
    showPopup(<CampaignErrorPopup message={t('error.unable-to-close')} onContactPress={handleContactSupport} />, {
      keepPopups: true,
    });
  };

  const showReopenCampaignErrorPopup = () => {
    showPopup(<CampaignErrorPopup message={t('error.unable-to-reopen')} onContactPress={handleContactSupport} />, {
      keepPopups: true,
    });
  };

  return { showAddCampaignErrorPopup, showCloseCampaignErrorPopup, showReopenCampaignErrorPopup };
};
