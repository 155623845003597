import { useTranslation } from 'react-i18next';

import FormStepWrapper from 'components/form/FormStepWrapper';
import { useAddCampaign } from 'features/add-campaign/context/AddCampaignProvider';
import CampaignSummaryForm from 'features/add-campaign/form/components/CampaignSummaryForm';

const Confirm = () => {
  const { t } = useTranslation('addCampaign');
  const { isFormValid, save } = useAddCampaign();

  return (
    <FormStepWrapper header={t('confirm.header')} isValid={isFormValid} onNextPress={save}>
      <CampaignSummaryForm />
    </FormStepWrapper>
  );
};

export default Confirm;
