import { useTranslation } from 'react-i18next';

import { useConfirmPopup } from 'components/popups/useConfirmPopup';
import { useCampaignError } from 'features/add-campaign/form/error/useCampaignError';
import { useCampaignPopup } from 'features/add-campaign/form/useCampaignPopup';
import { useNotification } from 'providers/NotificationProvider';
import { campaignService } from 'services/campaign/campaignService';
import { CampaignDto } from 'services/campaign/campaignService.dto';

export const useManageCampaign = () => {
  const { showConfirmPopup } = useConfirmPopup();
  const { showEditCampaignPopup } = useCampaignPopup();
  const { showCloseCampaignErrorPopup, showReopenCampaignErrorPopup } = useCampaignError();

  const { showNotification } = useNotification();
  const { t } = useTranslation('addCampaign');

  const editCampaign = (campaign: CampaignDto, onSuccess?: () => void) => {
    showEditCampaignPopup(campaign, onSuccess);
  };

  const closeCampaign = (campaignId: number, onSuccess?: () => void) => {
    const onAccept = async () => {
      return campaignService
        .closeCampaign(campaignId)
        .then(() => onSuccess?.())
        .catch(e => {
          showCloseCampaignErrorPopup();
          throw e;
        });
    };
    showConfirmPopup({ msg: t('close-confirmation-msg'), onYes: onAccept });
  };

  const reopenCampaign = (campaignId: number, onSuccess?: () => void) => {
    campaignService
      .reopenCampaign(campaignId)
      .then(() => {
        onSuccess?.();
        showNotification({ title: t('reopened-confirmation-msg'), type: 'success' });
      })
      .catch(e => {
        showReopenCampaignErrorPopup();
        throw e;
      });
  };

  return { editCampaign, closeCampaign, reopenCampaign };
};
