import { useTranslation } from 'react-i18next';

import FormStepWrapper from 'components/form/FormStepWrapper';
import Checkbox from 'components/inputs/Checkbox';
import PriceInputField from 'components/inputs/PriceInputField';
import { useAddItem } from 'features/add-item/context/AddItemProvider';
import { usePaymentConfig } from 'providers/cache/cachedDataHooks';

export const SetPriceContent = () => {
  const { t } = useTranslation('addItem');
  const { formData, setFieldValue, errors, isUnitSold, hasAuctionBids } = useAddItem();
  const { paymentConfig } = usePaymentConfig();

  const resolvePlaceholder = () => {
    if (formData.sellingMethod === 'AUCTION') return t('set-auction-price.price-placeholder');
    if (formData.sellingMethod === 'MULTIPLE') return t('set-multiple-items-price.price');
    return t('set-price.price-placeholder');
  };

  const handleChange = (value?: number) => {
    setFieldValue('price', value);
  };

  return (
    <>
      <PriceInputField
        value={formData.price}
        onChange={handleChange}
        placeholder={resolvePlaceholder()}
        maxValue={paymentConfig?.maxItemPrice}
        errorMessage={errors.price}
        disabled={isUnitSold || hasAuctionBids}
      />
      {!!formData.price && formData.sellingMethod === 'STANDARD' && (
        <Checkbox
          testId={'negotiable-checkbox'}
          checked={formData.isNegotiable}
          onChange={value => setFieldValue('isNegotiable', value)}
          label={t('set-price.accept-hi-lo-price')}
        />
      )}
    </>
  );
};

const SetPrice = () => {
  const { t } = useTranslation('addItem');
  const { formData, errors } = useAddItem();

  const isMultiple = formData.sellingMethod === 'MULTIPLE';
  const isAuction = formData.sellingMethod === 'AUCTION';

  const header = () => {
    if (isAuction) return t('set-auction-price.header');
    if (isMultiple) return t('set-multiple-items-price.header');
    return t('set-price.header');
  };

  const description = () => {
    if (isAuction) return t('set-auction-price.description');
    if (isMultiple) return t('set-multiple-items-price.description');
    return t('set-price.description');
  };

  return (
    <FormStepWrapper header={header()} description={description()} isValid={!errors.price}>
      <SetPriceContent />
    </FormStepWrapper>
  );
};

export default SetPrice;
