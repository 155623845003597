import i18n from 'i18next';

import { currentDate } from 'utils/backendDateParser';
import { dateWithOffset, isTheSameDay, startOfDay } from 'utils/dateUtils';

type VariantOfTimeType =
  | 'NO_DEADLINE'
  | 'ONE_WEEK'
  | 'ONE_MONTH'
  | 'THREE_MONTHS'
  | 'SIX_MONTHS'
  | 'TWELVE_MONTHS'
  | 'CUSTOM_DATE';

class VariantOfTime {
  id: VariantOfTimeType;
  text: string;
  amount?: number;
  unit?: 'weeks' | 'months';

  constructor(id: VariantOfTimeType, text: string, amount?: number, unit?: 'weeks' | 'months') {
    this.id = id;
    this.text = text;
    this.amount = amount;
    this.unit = unit;
  }

  getDateFrom() {
    return startOfDay(currentDate());
  }

  getDateTo() {
    if (!this.amount || !this.unit) return undefined;
    return dateWithOffset(this.getDateFrom(), this.amount, this.unit);
  }

  isSelected(dateFrom?: Date, dateTo?: Date) {
    if (!dateFrom) return false;
    if (!dateTo) return this.id === 'NO_DEADLINE';
    return isTheSameDay(dateFrom, this.getDateFrom()) && isTheSameDay(dateTo, this.getDateTo());
  }
}

export const variantOfTimes: VariantOfTime[] = [
  new VariantOfTime('NO_DEADLINE', i18n.t('addCampaign:timeline.variant-of-time.no-deadline')),
  new VariantOfTime('ONE_WEEK', i18n.t('addCampaign:timeline.variant-of-time.one-week'), 1, 'weeks'),
  new VariantOfTime('ONE_MONTH', i18n.t('addCampaign:timeline.variant-of-time.one-month'), 1, 'months'),
  new VariantOfTime('THREE_MONTHS', i18n.t('addCampaign:timeline.variant-of-time.three-months'), 3, 'months'),
  new VariantOfTime('SIX_MONTHS', i18n.t('addCampaign:timeline.variant-of-time.six-months'), 6, 'months'),
  new VariantOfTime('TWELVE_MONTHS', i18n.t('addCampaign:timeline.variant-of-time.twelve-months'), 12, 'months'),
];
