import dayjs from 'dayjs';

export const isTheSameDay = (date1?: Date, date2?: Date): boolean => {
  if (!date1 || !date2) return false;
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  );
};

export const isDateBefore = (date?: Date, referenceDate?: Date): boolean => {
  if (!date || !referenceDate) return false;
  return date.getTime() < referenceDate.getTime();
};

export const isDateAfter = (date?: Date, referenceDate?: Date): boolean => {
  if (!date || !referenceDate) return false;
  return date.getTime() > referenceDate.getTime();
};

export const isDateInRange = (date: Date, dateStart: Date, dateEnd: Date) => {
  return isDateBefore(date, dateEnd) && isDateAfter(date, dateStart);
};

export const startOfDay = (date?: Date) => {
  return dayjs(date).startOf('day').toDate();
};

export const dateWithOffset = (date: Date, value: number, unit: 'days' | 'weeks' | 'months') => {
  return dayjs(date).add(value, unit).toDate();
};
