import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import ConfirmRow from 'components/form/ConfirmRow';
import DateRangeField from 'components/inputs/DateRangeField';
import MultiDropdownField from 'components/inputs/MultiDropdownField';
import PriceInputField from 'components/inputs/PriceInputField';
import TextArea from 'components/inputs/TextArea';
import TextInputField from 'components/inputs/TextInputField';
import LocationByZipPicker from 'components/location/LocationByZipPicker';
import Spinner from 'components/ui-elements/Spinner';
import { MAX_CAMPAIGN_GOAL, useAddCampaign } from 'features/add-campaign/context/AddCampaignProvider';
import { AddPhotosStepContent } from 'features/add-campaign/form/steps/AddPhotos';
import { useCampaignCategories } from 'providers/cache/cachedDataHooks';
import { currentDate } from 'utils/backendDateParser';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 100%;
  min-height: 400px;
`;

const CampaignSummaryForm = () => {
  const { t } = useTranslation('addCampaign');
  const { formData, errors, setFieldValue, setValues, isSaving, isEditMode } = useAddCampaign();
  const { campaignCategories } = useCampaignCategories();

  const categoryOptions =
    campaignCategories?.map(category => {
      return { value: category.code, label: category.displayName };
    }) || [];

  const handleTimelineChange = (valueFrom?: Date, valueTo?: Date) => {
    setValues(prevValues => ({
      ...prevValues,
      timelineFrom: valueFrom,
      timelineTo: valueTo,
    }));
  };

  if (isSaving) {
    return (
      <Container>
        <Spinner />
      </Container>
    );
  }

  return (
    <Container>
      <ConfirmRow label={t('confirm.name')}>
        <TextInputField
          value={formData.name}
          onChange={value => setFieldValue('name', value)}
          placeholder={t('name.name-placeholder')}
          errorMessage={errors.name}
        />
      </ConfirmRow>
      <ConfirmRow label={t('confirm.description')}>
        <TextArea
          value={formData.description || ''}
          placeholder={t('description.description-input-placeholder')}
          onChange={value => setFieldValue('description', value)}
          errorMessage={errors.description}
        />
      </ConfirmRow>
      <ConfirmRow label={t('confirm.location')}>
        <LocationByZipPicker
          value={formData.campaignLocation}
          onChange={value => setFieldValue('campaignLocation', value)}
          validZipLength={5}
          placeholder={t('description.zip-placeholder')}
        />
      </ConfirmRow>
      <ConfirmRow label={t('confirm.categories')}>
        <MultiDropdownField
          testId={'category-confirm-select'}
          values={formData.categories}
          options={categoryOptions}
          onChange={values => setFieldValue('categories', values)}
          placeholder={t('name.categories-placeholder')}
        />
      </ConfirmRow>
      <ConfirmRow label={t('confirm.goal')}>
        <PriceInputField
          value={formData.moneyGoal}
          onChange={value => setFieldValue('moneyGoal', value)}
          placeholder={t('goal.input-goal-placeholder')}
          maxValue={MAX_CAMPAIGN_GOAL}
          errorMessage={errors.moneyGoal}
        />
      </ConfirmRow>
      <ConfirmRow label={t('confirm.timeline')}>
        <DateRangeField
          valueFrom={formData.timelineFrom}
          valueTo={formData.timelineTo}
          onChange={handleTimelineChange}
          minDate={currentDate()}
          canEditDateFrom={!isEditMode}
          placeholder={t('timeline.custom-date-placeholder')}
          testId={'timeline-input'}
        />
      </ConfirmRow>
      <ConfirmRow label={t('confirm.photos')}>
        <AddPhotosStepContent />
      </ConfirmRow>
    </Container>
  );
};

export default CampaignSummaryForm;
