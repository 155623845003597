import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import styled from 'styled-components';

import calendarIcon from 'assets/svg/calendar.svg';
import { BaseInputProps } from 'components/inputs/BaseInputField';
import TextInputField from 'components/inputs/TextInputField';
import { usePopup } from 'hooks/usePopup';
import { formatDateLong } from 'utils/dateFormatter';

import { RangeCalendar } from './date/RangeCalendar';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const CalendarIcon = styled.img`
  position: absolute;
  cursor: pointer;
  right: 12px;
`;

interface Props extends Omit<BaseInputProps, 'value' | 'onChange'> {
  valueFrom?: Date;
  valueTo?: Date;
  onChange: (valueFrom?: Date, valueTo?: Date) => void;
  minDate?: Date;
  canEditDateFrom?: boolean;
}

const DateRangeField = (props: Props) => {
  const { valueFrom, valueTo, onChange, minDate, canEditDateFrom, ...otherProps } = props;
  const { showPopup, hidePopup } = usePopup();

  const formatedValue = () => {
    if (!valueFrom) return '';
    return `${formatDateLong(valueFrom)} - ${valueTo ? formatDateLong(valueTo) : ''}`;
  };

  const handleChange = (startDate?: Date, endDate?: Date) => {
    hidePopup();
    onChange(startDate, endDate);
  };

  const openCalendar = () => {
    showPopup(
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <RangeCalendar
          dateFrom={valueFrom}
          dateTo={valueTo}
          onAccept={handleChange}
          onClose={() => hidePopup(false)}
          minDate={minDate}
          canEditDateFrom={canEditDateFrom ?? true}
        />
      </LocalizationProvider>,
      {
        keepPopups: true,
        hideClose: true,
        paddingVariant: 'NONE',
      }
    );
  };

  return (
    <Container onClick={openCalendar}>
      <TextInputField value={formatedValue()} onChange={() => {}} readonly={true} {...otherProps} />
      <CalendarIcon src={calendarIcon} />
    </Container>
  );
};

export default DateRangeField;
