import { PropsWithChildren } from 'react';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { H6 } from 'components/typography/Headers';

const RowContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  padding-bottom: 12px;
  align-items: start;
  text-align: start;
  justify-content: center;
  border-bottom: 1px solid ${theme.color.borderGray};
  gap: 6px;

  &:last-child {
    border-bottom: none;
  }
`;

interface Props extends PropsWithChildren {
  label: string;
}

const ConfirmRow = ({ label, children }: Props) => {
  return (
    <RowContainer>
      <H6>{label}</H6>
      {children}
    </RowContainer>
  );
};

export default ConfirmRow;
