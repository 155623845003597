import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import FormStepWrapper from 'components/form/FormStepWrapper';
import TextArea from 'components/inputs/TextArea';
import LocationByZipPicker from 'components/location/LocationByZipPicker';
import { Text1 } from 'components/typography/Texts';
import { useAddCampaign } from 'features/add-campaign/context/AddCampaignProvider';

const LocationDescription = styled(Text1)`
  margin: 12px 18px;
`;

const SetDescription = () => {
  const { t } = useTranslation('addCampaign');
  const { formData, setFieldValue, errors } = useAddCampaign();
  const isValid = !errors.description && !errors.campaignLocation;

  return (
    <FormStepWrapper header={t('description.header')} description={t('description.description')} isValid={isValid}>
      <TextArea
        value={formData.description || ''}
        placeholder={t('description.description-input-placeholder')}
        onChange={value => setFieldValue('description', value)}
        errorMessage={errors.description}
      />
      <LocationDescription>{t('description.location-description')}</LocationDescription>
      <LocationByZipPicker
        value={formData.campaignLocation}
        onChange={value => setFieldValue('campaignLocation', value)}
        validZipLength={5}
        placeholder={t('description.zip-placeholder')}
      />
    </FormStepWrapper>
  );
};

export default SetDescription;
