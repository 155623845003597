import { createContext, PropsWithChildren, SetStateAction, useContext, useState } from 'react';
import { FormikErrors } from 'formik';

import { useCampaignError } from 'features/add-campaign/form/error/useCampaignError';
import { useSharePopup } from 'features/share/useSharePopup';
import { campaignService } from 'services/campaign/campaignService';
import { CampaignDto, EditCampaignDto, NewCampaignDto } from 'services/campaign/campaignService.dto';

import { CampaignFormData, useCampaignForm } from './useCampaignForm';

export const MAX_PHOTOS_COUNT = 12;
export const MAX_PHOTO_SIZE = 15 * 1024 * 1024;
export const MAX_CAMPAIGN_GOAL = 1e11;
export const DESCRIPTION_LENGTH_LIMIT = 20000;
export const NAME_LENGTH_LIMIT = 255;

interface AddCampaignContextType {
  save: () => Promise<void>;
  isSaving: boolean;
  addPhoto: (file: File) => void;
  deletePhoto: (id: string) => void;
  movePhoto: (from: number, to: number) => void;
  maxPhotosCount?: number;
  formData: CampaignFormData;
  setFieldValue: (field: string, value: any) => void;
  setValues: (
    values: SetStateAction<CampaignFormData>,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<CampaignFormData>>;
  errors: FormikErrors<CampaignFormData>;
  isEditMode: boolean;
  isFormValid: boolean;
}

const AddCampaignContext = createContext<AddCampaignContextType>(null!);

export interface AddCampaignContextProps extends PropsWithChildren {
  campaign?: CampaignDto;
  onCampaignSaved?: () => void;
}

const AddCampaignProvider = ({ campaign, onCampaignSaved, children }: AddCampaignContextProps) => {
  const [isSaving, setIsSaving] = useState(false);
  const { showAddCampaignErrorPopup } = useCampaignError();
  const { showCampaignCreatedSharePopup } = useSharePopup();

  const postCampaign = async (request: NewCampaignDto) => {
    setIsSaving(true);
    try {
      const createdCampaign = await campaignService.postCampaign(request);
      showCampaignCreatedSharePopup(createdCampaign.data);
      onCampaignSaved && onCampaignSaved();
    } catch (error) {
      showAddCampaignErrorPopup();
    } finally {
      setIsSaving(false);
    }
  };

  const updateCampaign = async (request: EditCampaignDto) => {
    setIsSaving(true);
    try {
      await campaignService.editCampaign(request);
      onCampaignSaved && onCampaignSaved();
    } catch (error) {
      showAddCampaignErrorPopup();
    } finally {
      setIsSaving(false);
    }
  };

  const campaignForm = useCampaignForm(postCampaign, updateCampaign, campaign);

  const isEditMode = !!campaign;

  return (
    <AddCampaignContext.Provider
      value={{
        save: campaignForm.submitForm,
        isSaving,
        formData: campaignForm.values,
        maxPhotosCount: MAX_PHOTOS_COUNT,
        isFormValid: campaignForm.isValid && campaignForm.dirty && !campaignForm.isValidating,
        isEditMode,
        ...campaignForm,
      }}>
      {children}
    </AddCampaignContext.Provider>
  );
};

const useAddCampaign = () => useContext(AddCampaignContext);

export { AddCampaignProvider, useAddCampaign };
