import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useCampaignPopup } from 'features/add-campaign/form/useCampaignPopup';
import { useItemPopup } from 'features/add-item/form/useItemPopup';
import { useGetApp } from 'features/get-app/useGetApp';
import { MessageDialogButton } from 'features/messenger/components/NotificationMessage';
import { useSharePopup } from 'features/share/useSharePopup';
import { NotificationContent, useNotification } from 'providers/NotificationProvider';
import {
  createButtonLabel,
  createDescription,
  createPlaceholder,
  createTitle,
  notificationActions,
  notificationRefs,
} from 'providers/utils/notifications/notificationActions';

export const useBackendNotification = () => {
  const { showNotification } = useNotification();
  const { showGetApp } = useGetApp();
  const { showShare } = useSharePopup();
  const { showAddItemPopup } = useItemPopup();
  const { showAddCampaignPopup } = useCampaignPopup();
  const navigate = useNavigate();
  const { t } = useTranslation('notifications');

  const showError = (title?: string, description?: string) => {
    const content: NotificationContent = {
      autoHide: true,
      title: title || t('invalid-action'),
      description,
      type: 'error',
    };
    showNotification(content);
  };

  const showSuccess = (title?: string, description?: string) => {
    const content: NotificationContent = {
      autoHide: true,
      title: title || t('success-action'),
      description,
      type: 'success',
    };
    showNotification(content);
  };

  const actions = notificationActions({
    showGetApp,
    showError,
    showSuccess,
    navigate,
    showShare,
    showAddItem: () => showAddItemPopup(),
    showAddCampaign: () => showAddCampaignPopup(),
  });

  const createInfoNotificationFromPayload = (
    notificationPayloadType: string,
    attributes?: any,
    callback?: (data?: any) => void
  ): NotificationContent => {
    const notificationDefinition = notificationRefs().get(notificationPayloadType);

    if (!notificationDefinition) return createInfoNotificationFromPayload('DEFAULT');

    const buttons: MessageDialogButton[] = notificationDefinition.actions.map((action, index) => ({
      label: createButtonLabel(notificationPayloadType, index),
      action: actions.get(action)!,
    }));

    const title = createTitle(notificationPayloadType, attributes);
    const description = createDescription(notificationPayloadType, attributes);
    const placeholder = createPlaceholder(notificationPayloadType);
    return {
      title,
      description,
      firstButton: buttons.length > 0 ? buttons[0] : undefined,
      secondButton: buttons.length > 1 ? buttons[1] : undefined,
      type: 'info',
      inputType: notificationDefinition.inputType || 'Text',
      callback,
      attributes,
      messengerOnly: notificationDefinition.messengerOnly,
      priceInputPlaceholder: placeholder,
    };
  };

  return { createInfoNotificationFromPayload };
};
