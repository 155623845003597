import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { CreateButton } from 'components/buttons/CreateButton';
import { RedTextButton } from 'components/buttons/RedTextButton';
import CampaignTile from 'components/campaign/campaign-tile/CampaignTile';
import { LoadingContainer } from 'components/containers/Containers';
import { H4 } from 'components/typography/Headers';
import { LoadingIndicator } from 'components/ui-elements/LoadingIndicator';
import { useCampaignPopup } from 'features/add-campaign/form/useCampaignPopup';
import { useResponsive } from 'hooks/useResponsive';
import useMyCampaigns from 'services/campaign/useMyCampaigns';

import { NoContent } from './NoContent';

const Container = styled.div`
  margin-top: 48px;

  ${theme.mq.phone} {
    margin-top: 24px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  margin-bottom: 24px;

  ${theme.mq.tablet} {
    gap: 12px;
  }
`;

const TilesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  gap: 24px;

  ${theme.mq.tablet} {
    gap: 12px;
  }
`;

const CAMPAIGNS_PREVIEW_SIZE = 2;

export const CampaignsSection = () => {
  const { t } = useTranslation('myProfile');
  const { state } = useMyCampaigns({ pageSize: CAMPAIGNS_PREVIEW_SIZE });
  const showSeeAll = !state.loading && state.hasNextPage;
  const { showAddCampaignPopup } = useCampaignPopup();
  const onCreateClick = () => showAddCampaignPopup();
  const navigate = useNavigate();
  const { isMobile } = useResponsive();

  const renderCampaigns = () => (
    <TilesContainer>
      {state.campaigns.map(campaign => (
        <CampaignTile key={campaign.id} campaign={campaign} onClick={() => navigate(`/campaigns/${campaign.id}`)} />
      ))}
    </TilesContainer>
  );

  const renderNoContent = () => (
    <NoContent
      text={t('my-activity.no-campaigns')}
      buttonText={t('my-activity.create-first')}
      onClick={onCreateClick}
    />
  );

  const resolveButtonText = () => (isMobile ? t('my-activity.create') : t('my-activity.create-campaign'));

  const renderLoading = () => (
    <LoadingContainer>
      <LoadingIndicator />
    </LoadingContainer>
  );

  const renderContent = () => {
    if (state.loading) return renderLoading();
    if (state.campaigns && state.campaigns.length > 0) return renderCampaigns();
    return renderNoContent();
  };

  const handleSeeAll = () => navigate('/my-profile/my-activity/campaigns');

  return (
    <Container data-testid={'campaigns-section'}>
      <HeaderContainer>
        <H4>{t('my-activity.campaigns')}</H4>
        <CreateButton onClick={onCreateClick}>{resolveButtonText()}</CreateButton>
        {showSeeAll && <RedTextButton onClick={handleSeeAll}>{t('my-activity.see-all')}</RedTextButton>}
      </HeaderContainer>
      {renderContent()}
    </Container>
  );
};
