import { LocalizationProvider, renderTimeViewClock, StaticDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import styled from 'styled-components';

import calendarIcon from 'assets/svg/calendar.svg';
import { usePopup } from 'hooks/usePopup';
import { formatDateTime } from 'utils/dateFormatter';

import { BaseInputProps } from './BaseInputField';
import { calendarStyles } from './date/calendar.styles';
import TextInputField from './TextInputField';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const CalendarIcon = styled.img`
  position: absolute;
  cursor: pointer;
  right: 12px;
`;

const CustomDateTimePicker = styled(StaticDateTimePicker)`
  ${calendarStyles}
`;

interface Props extends Omit<BaseInputProps, 'value' | 'onChange'> {
  value?: Date;
  onChange: (value?: Date) => void;
  minDate?: Date;
}

const DateInputField = (props: Props) => {
  const { value, onChange, minDate, ...otherProps } = props;
  const { showPopup, hidePopup } = usePopup();

  const formattedValue = value ? formatDateTime(value) : '';

  const handleDateFromCalendarChange = (inputValue: Dayjs | null) => {
    onChange(inputValue?.toDate());
  };

  const openCalendar = () => {
    showPopup(
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CustomDateTimePicker
          value={dayjs(value)}
          onChange={handleDateFromCalendarChange}
          views={['year', 'month', 'day', 'hours', 'minutes']}
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
          }}
          onClose={hidePopup}
          minDateTime={minDate && dayjs(minDate)}
        />
      </LocalizationProvider>,
      { keepPopups: true, hideClose: true, paddingVariant: 'NONE' }
    );
  };

  return (
    <Container onClick={openCalendar}>
      <TextInputField value={formattedValue} onChange={() => {}} readonly={true} {...otherProps} />
      <CalendarIcon src={calendarIcon} />
    </Container>
  );
};

export default DateInputField;
