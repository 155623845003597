import { css } from 'styled-components';

import { theme } from 'assets/styles/theme';

export const calendarStyles = css`
  & .MuiPickersToolbar-root {
    background-color: ${theme.color.red};
    color: ${theme.color.white};
  }

  & .MuiPickersToolbarText-root {
    color: ${theme.color.white};
  }

  & .MuiPickersToolbarText-root.Mui-selected {
    color: ${theme.color.white};
  }

  & .MuiOutlinedInput-notchedOutline {
    border-color: ${theme.color.darkerGray};
  }

  & .MuiSvgIcon-root {
    color: ${theme.color.red};
    font-size: 20px;
  }

  & .MuiTabs-indicator {
    background-color: ${theme.color.red};
  }

  & .MuiDateTimePickerToolbar-ampmLabel.Mui-selected {
    font-weight: 700;
    text-decoration: underline;
    color: ${theme.color.white};
  }

  & .MuiPickersDay-root.Mui-selected {
    background-color: ${theme.color.red};
    color: ${theme.color.white};
  }

  & .MuiPickersDay-root.Mui-selected:focus {
    background-color: ${theme.color.red};
    color: ${theme.color.white};
  }

  & .MuiTypography-overline {
    display: none;
  }

  & .MuiPickersCalendarHeader-label {
    font-size: 14px;
  }

  & .MuiDayCalendar-weekDayLabel {
    font-size: 14px;
    margin: 0;
  }

  & .MuiPickersDay-root {
    font-size: 18px;
    margin: 0;
  }

  & .MuiClockNumber-root {
    font-size: 18px;
  }

  & .MuiClockNumber-root.Mui-selected {
    background-color: ${theme.color.red};
    color: ${theme.color.white};
  }

  & .MuiClockPointer-root {
    background-color: ${theme.color.red};
  }

  & .MuiClock-pin {
    background-color: ${theme.color.red};
  }

  & .MuiClockPointer-thumb {
    background-color: ${theme.color.red};
    border-color: ${theme.color.red};
  }

  & .MuiDateTimePickerToolbar-separator {
    margin-top: 6px;
  }

  & .MuiDialogActions-root .MuiButtonBase-root {
    background-color: ${theme.color.red};
    height: 30px;
    font-size: 14px;
    color: ${theme.color.white};
  }
`;
