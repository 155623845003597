import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import deleteIcon from 'assets/svg/delete-icon.svg';
import editIcon from 'assets/svg/edit-paper-icon.svg';
import { Text1 } from 'components/typography/Texts';
import Spinner from 'components/ui-elements/Spinner';
import { useManageCampaign } from 'features/campaign/hooks/useManageCampaign';
import { useAuth } from 'providers/AuthProvider';
import { useCurrentUser } from 'providers/cache/cachedDataHooks';
import { CampaignDto } from 'services/campaign/campaignService.dto';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  justify-content: center;
`;

const Button = styled.div`
  display: flex;
  gap: 10px;
  color: ${theme.color.red};
  cursor: pointer;
`;

interface Props {
  campaign: CampaignDto;
  refreshCampaignData?: () => void;
}

export const ManageCampaignSection = ({ campaign, refreshCampaignData }: Props) => {
  const { t } = useTranslation();
  const { editCampaign, closeCampaign, reopenCampaign } = useManageCampaign();
  const { logged } = useAuth();
  const { currentUser } = useCurrentUser();

  if (!logged) return <></>;

  if (!currentUser)
    return (
      <Container>
        <Spinner />
      </Container>
    );

  const isOwner = campaign.ownerId === currentUser.id;
  const canEditAndClose = campaign.status === 'ACTIVE';
  const canReopen = campaign.status === 'CLOSED';

  if (!isOwner) return <></>;

  if (canEditAndClose) {
    return (
      <Container>
        <Button onClick={() => editCampaign(campaign, refreshCampaignData)}>
          <img src={editIcon} alt={t('edit')} />
          <Text1>{t('edit')}</Text1>
        </Button>
        <Button onClick={() => closeCampaign(campaign.id, refreshCampaignData)}>
          <img src={deleteIcon} alt={t('close')} />
          <Text1>{t('close')}</Text1>
        </Button>
      </Container>
    );
  }
  if (canReopen) {
    return (
      <Container>
        <Button onClick={() => reopenCampaign(campaign.id, refreshCampaignData)}>
          <img src={editIcon} alt={t('reopen')} />
          <Text1>{t('reopen')}</Text1>
        </Button>
      </Container>
    );
  }
  return <></>;
};
