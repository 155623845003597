import { useTranslation } from 'react-i18next';

import FormStepWrapper from 'components/form/FormStepWrapper';
import PriceInputField from 'components/inputs/PriceInputField';
import { MAX_CAMPAIGN_GOAL, useAddCampaign } from 'features/add-campaign/context/AddCampaignProvider';

const SetGoal = () => {
  const { t } = useTranslation('addCampaign');
  const { formData, setFieldValue, errors } = useAddCampaign();

  return (
    <FormStepWrapper header={t('goal.header')} description={t('goal.description')} isValid={!errors.moneyGoal}>
      <PriceInputField
        value={formData.moneyGoal}
        onChange={value => setFieldValue('moneyGoal', value)}
        placeholder={t('goal.input-goal-placeholder')}
        maxValue={MAX_CAMPAIGN_GOAL}
      />
    </FormStepWrapper>
  );
};

export default SetGoal;
