import { useTranslation } from 'react-i18next';

import FormStepWrapper from 'components/form/FormStepWrapper';
import MultiDropdownField from 'components/inputs/MultiDropdownField';
import TextInputField from 'components/inputs/TextInputField';
import { useAddCampaign } from 'features/add-campaign/context/AddCampaignProvider';
import { useCampaignCategories } from 'providers/cache/cachedDataHooks';

const SetName = () => {
  const { t } = useTranslation('addCampaign');
  const { formData, setFieldValue, errors } = useAddCampaign();
  const { campaignCategories } = useCampaignCategories();

  const isValid = !errors.name && !errors.categories;

  const categoryOptions =
    campaignCategories?.map(category => {
      return { value: category.code, label: category.displayName };
    }) || [];

  return (
    <FormStepWrapper header={t('name.header')} description={t('name.description')} isValid={isValid}>
      <TextInputField
        value={formData.name}
        onChange={value => setFieldValue('name', value)}
        placeholder={t('name.name-placeholder')}
      />
      <MultiDropdownField
        testId={'category-select'}
        values={formData.categories}
        options={categoryOptions}
        onChange={values => setFieldValue('categories', values)}
        placeholder={t('name.categories-placeholder')}
      />
    </FormStepWrapper>
  );
};

export default SetName;
