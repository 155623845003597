import styled from 'styled-components';

import { MultiStepFormProvider } from 'components/form/MultiStepFormProvider';
import { AddCampaignProvider } from 'features/add-campaign/context/AddCampaignProvider';

import AddPhotos from './steps/AddPhotos';
import Confirm from './steps/Confirm';
import SetDescription from './steps/SetDescription';
import SetGoal from './steps/SetGoal';
import SetName from './steps/SetName';
import SetTimeline from './steps/SetTimeline';

const Container = styled.div`
  width: 100%;
  display: flex;
`;

const AddCampaignForm = () => {
  return (
    <Container data-testid={'add-campaign-form'}>
      <MultiStepFormProvider>
        <SetName />
        <AddPhotos />
        <SetGoal />
        <SetTimeline />
        <SetDescription />
        <Confirm />
      </MultiStepFormProvider>
    </Container>
  );
};

const AddCampaignFormWithContext = () => {
  return (
    <AddCampaignProvider>
      <AddCampaignForm />
    </AddCampaignProvider>
  );
};

export default AddCampaignFormWithContext;
