import { useTranslation } from 'react-i18next';

import { useConfirmPopup } from 'components/popups/useConfirmPopup';
import { useLoginPopup } from 'features/account';
import { useGetApp } from 'features/get-app/useGetApp';
import { usePopup } from 'hooks/usePopup';
import { useAuth } from 'providers/AuthProvider';
import { useCurrentUser } from 'providers/cache/cachedDataHooks';
import { useNotification } from 'providers/NotificationProvider';
import { CampaignDto } from 'services/campaign/campaignService.dto';
import { userService } from 'services/user/userService';
import { CurrentUserDto } from 'services/user/userService.dto';

import AddCampaignForm from './AddCampaignForm';
import EditCampaignForm from './EditCampaignForm';

export const useCampaignPopup = () => {
  const { t } = useTranslation('addCampaign');
  const { logged } = useAuth();
  const { currentUser } = useCurrentUser();
  const { showLoginPopup } = useLoginPopup();
  const { showPopup, hidePopup } = usePopup();
  const { showConfirmPopup } = useConfirmPopup();
  const { showGetApp } = useGetApp();
  const { showNotification } = useNotification();

  const isLoading = logged && !currentUser;

  const handleClose = () => showConfirmPopup({ msg: t('quit-form-msg'), onYes: hidePopup });

  const checkUserAndOpenPopup = (openFormPopup: () => void) => {
    if (!logged) showLoginPopup({ onLogged: () => verifyUserDataFetchedAndOpenPopup(openFormPopup) });
    else verifyUserDataFetchedAndOpenPopup(openFormPopup);
  };

  const verifyUserDataFetchedAndOpenPopup = (openFormPopup: () => void) => {
    if (!!currentUser) verifyUserCanCreateCampaignAndOpenPopup(currentUser, openFormPopup);
    else {
      // Probably it could be optimized to not fetch currentUser twice, but there is no easy way to access
      // cache data without using hooks
      userService
        .fetchCurrentUser()
        .then(currentUser => verifyUserCanCreateCampaignAndOpenPopup(currentUser.data, openFormPopup))
        .catch(() => showNotification({ title: t('common:global-error.unknown-error-message'), type: 'error' }));
    }
  };

  const verifyUserCanCreateCampaignAndOpenPopup = (user: CurrentUserDto, openFormPopup: () => void) => {
    if (user.ableToCreateCampaigns) openFormPopup();
    else
      showGetApp({
        header: t('common:get-app.header-see'),
        description: t('myProfile:become-fundraiser.header'),
      });
  };

  const showAddCampaignPopup = () => {
    checkUserAndOpenPopup(() => showPopup(<AddCampaignForm />, { onClose: handleClose, paddingVariant: 'FORM' }));
  };

  const showEditCampaignPopup = (campaign: CampaignDto, onCampaignSaved?: () => void) => {
    const handleSaved = () => {
      hidePopup();
      onCampaignSaved && onCampaignSaved();
    };

    checkUserAndOpenPopup(() =>
      showPopup(<EditCampaignForm campaign={campaign} onCampaignSaved={handleSaved} />, {
        onClose: handleClose,
        paddingVariant: 'FORM',
      })
    );
  };

  return { showAddCampaignPopup, showEditCampaignPopup, isLoading };
};
