import { PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';

import { theme } from 'assets/styles/theme';
import { makeTransparentColor } from 'assets/styles/transparentColor';
import { isDateInRange, isTheSameDay } from 'utils/dateUtils';

export const RangeCalendarDay = (props: PickersDayProps<Dayjs> & { startDate?: Date; endDate?: Date }) => {
  const { day, selected, startDate, endDate, ...other } = props;

  const isInRange = startDate && endDate && isDateInRange(day.toDate(), startDate, endDate);
  const isStart = isTheSameDay(startDate, day.toDate());
  const isEnd = isTheSameDay(endDate, day.toDate());

  return (
    <PickersDay
      {...other}
      day={day}
      selected={isStart || isEnd}
      sx={{
        ...(isStart && { borderBottomRightRadius: 0, borderTopRightRadius: 0 }),
        ...(isEnd && { borderBottomLeftRadius: 0, borderTopLeftRadius: 0 }),
        ...(isInRange && {
          backgroundColor: makeTransparentColor(theme.color.red, 0.2),
          color: theme.color.black,
          borderRadius: 0,
        }),
      }}
    />
  );
};
