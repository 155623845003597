import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import ContactButton from 'components/buttons/ContactButton';
import { H4 } from 'components/typography/Headers';
import { Text1 } from 'components/typography/Texts';

const Container = styled.div`
  width: 100%;
`;

const Info = styled(Text1)`
  padding: 36px 0;
`;

interface Props {
  onContactPress: () => void;
  message?: string;
}

const CampaignErrorPopup = ({ onContactPress, message }: Props) => {
  const { t } = useTranslation('addCampaign');

  return (
    <Container data-testid={'campaign-error-popup'}>
      <H4>{t('common:sorry')}</H4>
      <Info>{message || t('error.unable-to-list')}</Info>
      <ContactButton onClick={onContactPress}>{t('common:contact-support')}</ContactButton>
    </Container>
  );
};

export default CampaignErrorPopup;
