import { useNavigate } from 'react-router-dom';

import ShareCampaignPopup from 'features/add-campaign/form/ShareCampaignPopup';
import ShareItemPopup from 'features/add-item/form/ShareItemPopup';
import { usePopup } from 'hooks/usePopup';
import { ROUTE_CAMPAIGNS, ROUTE_ITEMS } from 'navigation/config/routesConfig';
import { CampaignDto } from 'services/campaign/campaignService.dto';
import { ItemDto } from 'services/item/itemService.dto';

import Share, { ShareProps } from './Share';

export const useSharePopup = () => {
  const { showPopup, hidePopup } = usePopup();
  const navigate = useNavigate();

  const showShare = (options?: ShareProps) => {
    showPopup(<Share {...options} />);
  };

  const showItemCreatedSharePopup = (item: ItemDto, campaign?: CampaignDto) => {
    const goToItem = () => {
      hidePopup();
      navigate(`${ROUTE_ITEMS}/${item.id}`);
    };
    showPopup(
      <ShareItemPopup
        shareType={'ITEM_CREATED'}
        objectId={item.id}
        item={item}
        campaign={campaign}
        onGoToItem={goToItem}
      />
    );
  };

  const showCampaignCreatedSharePopup = (campaign: CampaignDto) => {
    const goToCampaign = () => {
      hidePopup();
      navigate(`${ROUTE_CAMPAIGNS}/${campaign.id}`);
    };
    showPopup(
      <ShareCampaignPopup
        shareType={'CAMPAIGN_READY'}
        objectId={campaign.id}
        campaign={campaign}
        onGoToCampaign={goToCampaign}
      />
    );
  };

  return { showShare, showItemCreatedSharePopup, showCampaignCreatedSharePopup };
};
