import { NavigateFunction } from 'react-router/dist/lib/hooks';
import i18n from 'i18next';

import { openInNewTab } from 'components/ui-elements/StoreBanner';
import { ItemCheckoutState } from 'features/checkout/item/itemCheckoutState';
import { itemService } from 'services/item/itemService';
import { messageService } from 'services/message/messageService';
import { notificationService } from 'services/notification/notificationService';
import { orderService } from 'services/order/orderService';
import { PurchaseDto } from 'services/order/orderService.dto';
import { shipmentService } from 'services/shipment/shipmentService';

import { NotificationActionType } from './notificationActionType';
import { NotificationInputType } from './notificationInputType';
import { isOfferActive } from './offerUtils';

export interface NotificationDefinition {
  actions: NotificationActionType[];
  inputType?: NotificationInputType;
  messengerOnly?: boolean;
  customWarning?: boolean;
}

export interface NotificationActionCallbacks {
  navigate: NavigateFunction;
  showGetApp: (props?: any) => void;
  showSuccess: (title?: string, description?: string) => void;
  showError: (title?: string, description?: string) => void;
  showShare: (props?: any) => void;
  showAddItem: () => void;
  showAddCampaign: () => void;
}

export const notificationActions = (notificationActionCallbacks: NotificationActionCallbacks) => {
  const callbacks = new Map<NotificationActionType, (data?: any) => void>();

  callbacks.set('USER_PHONE_UPDATED', async data => {
    notificationActionCallbacks.showGetApp({
      header: i18n.t('common:get-app.header-see'),
      description: i18n.t('myProfile:account-settings.header'),
    });
  });

  callbacks.set('CLOSE_SUPPORT_CONVERSATION', async data => {
    messageService
      .closeSupportConversation(data.conversationId)
      .then(() => notificationActionCallbacks.showSuccess())
      .catch(() => notificationActionCallbacks.showError());
  });

  callbacks.set('SHARE_ITEM_ADDED', async data => {
    notificationActionCallbacks.showShare({
      shareType: 'MY_ITEM_FOR_SALE',
      objectId: data.itemId,
    });
  });

  callbacks.set('SHARE_CAMPAIGN_PUBLISHED', data => {
    notificationActionCallbacks.showShare({
      shareType: 'CAMPAIGN_READY',
      objectId: data.campaignId,
    });
  });

  callbacks.set('SHARE_CAMPAIGN_DONATED', data => {
    notificationActionCallbacks.showShare({
      shareType: 'CAMPAIGN_DONATED',
      objectId: data.campaignId,
    });
  });

  callbacks.set('GO_TO_ADD_CAMPAIGN', async data => {
    notificationActionCallbacks.showAddCampaign();
  });

  callbacks.set('GO_TO_ADD_ITEM', async data => {
    notificationActionCallbacks.showAddItem();
  });

  callbacks.set('GO_TO_MARKETPLACE', async data => {
    notificationActionCallbacks.showGetApp({ appAreaType: 'MARKETPLACE' });
  });

  callbacks.set('GO_TO_VERIFY_EMAIL', async data => {
    notificationActionCallbacks.showGetApp({
      header: i18n.t('common:get-app.header-see'),
      description: i18n.t('myProfile:account-settings.header'),
    });
  });

  callbacks.set('BUYER_GO_TO_MEETUP', async data => {
    goToMeetup(data.orderId, 'BUYER');
  });

  callbacks.set('SELLER_GO_TO_MEETUP', async data => {
    goToMeetup(data.orderId, 'SELLER');
  });

  const goToMeetup = (orderId: number, perspective: 'BUYER' | 'SELLER') => {
    orderService.fetchPurchaseDetails(orderId).then(response => {
      const purchase: PurchaseDto = response.data;
      if (purchase.status === 'AUTHORIZED' || purchase.status === 'CHARGED') {
        notificationActionCallbacks.navigate(`/messenger?meetupOrderId=${orderId}&perspective=${perspective}`);
      } else {
        notificationActionCallbacks.showError();
      }
    });
  };

  callbacks.set('GO_TO_BUY_ITEM', async ({ offerId, itemId }) => {
    const payForOffer = !!offerId && (await isOfferActive(offerId, itemId));
    const state: ItemCheckoutState = { payForOffer };
    notificationActionCallbacks.navigate(`/checkout/${itemId}`, { state });
  });

  callbacks.set('GO_TO_ITEM', async data => {
    notificationActionCallbacks.navigate('/items/' + data.itemId);
  });

  callbacks.set('GO_TO_TRACKED_ITEM_AS_BUYER', async data => {
    notificationActionCallbacks.showGetApp({ appAreaType: 'ITEM', objectId: data.itemId });
  });

  callbacks.set('MEETUP_NO', data => {
    notificationService
      .markMeetupAsNo(data.orderId)
      .then(() => notificationActionCallbacks.showSuccess())
      .catch(() => notificationActionCallbacks.showError());
  });

  callbacks.set('MEETUP_YES', data => {
    orderService
      .markAsPickedUp(data.orderId)
      .then(() => notificationActionCallbacks.showSuccess())
      .catch(() => notificationActionCallbacks.showError());
  });

  callbacks.set('CANCEL_OFFER', async data => {
    itemService
      .cancelOffer(data.offerId)
      .then(() => notificationActionCallbacks.showSuccess(i18n.t('notifications:offer-canceled')))
      .catch(() => notificationActionCallbacks.showError());
  });

  callbacks.set('ACCEPT_OFFER', data => {
    itemService
      .acceptOffer(data.offerId)
      .then(() => notificationActionCallbacks.showSuccess())
      .catch(() => notificationActionCallbacks.showError());
  });

  callbacks.set('DECLINE_OFFER', data => {
    itemService
      .rejectOffer(data.offerId)
      .then(() => notificationActionCallbacks.showSuccess(i18n.t('notifications:offer-declined')))
      .catch(() => notificationActionCallbacks.showError());
  });

  callbacks.set('SEND_NEW_OFFER', data => {
    itemService
      .registerOffer({ itemId: data.itemId, amount: data.input })
      .then(() => notificationActionCallbacks.showSuccess(i18n.t('notifications:offer-sent')))
      .catch(() => notificationActionCallbacks.showError());
  });

  callbacks.set('DO_NOTHING', async data => {
    // no-op
  });

  callbacks.set('GO_TO_MESSENGER', async data => {
    notificationActionCallbacks.navigate('/messenger/' + data.conversationId);
  });

  callbacks.set('GO_TO_ORDER_ADDRESS', async data => {
    notificationActionCallbacks.showGetApp({ appAreaType: 'ITEM', objectId: data.itemId });
  });

  callbacks.set('GO_TO_FAILED_ORDER_ADDRESS', async data => {
    notificationActionCallbacks.showGetApp({ appAreaType: 'ITEM', objectId: data.itemId });
  });

  callbacks.set('GO_TO_FAILED_ORDER_ADDRESS_BUYER', async data => {
    notificationActionCallbacks.showGetApp({ appAreaType: 'ITEM', objectId: data.itemId });
  });

  callbacks.set('GO_TO_EDIT_ITEM', async data => {
    notificationActionCallbacks.showGetApp({ appAreaType: 'ITEM', objectId: data.itemId });
  });

  callbacks.set('DOWNLOAD_LABEL', async data => {
    shipmentService
      .downloadLabel(data.shipmentId)
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = url;
        link.download = 'label.pdf';
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      })
      .catch(() => notificationActionCallbacks.showError());
  });

  callbacks.set('TRACK', async data => {
    try {
      openInNewTab(data.linkToTracking);
    } catch (e) {
      notificationActionCallbacks.showError();
    }
  });

  callbacks.set('SHARE_APP', async data => {
    notificationActionCallbacks.showShare();
  });

  callbacks.set('GO_TO_DONATE', async data => {
    notificationActionCallbacks.navigate('/campaigns/' + data.campaignId);
  });

  callbacks.set('GET_APP', async data => {
    notificationActionCallbacks.showGetApp();
  });

  return callbacks;
};

export const notificationRefs = () => {
  const refs = new Map<string, NotificationDefinition>();
  refs.set('INVITE_USERS', { actions: ['SHARE_APP'] });
  refs.set('MEET_UP_QUESTION_SEND_TO_BUYER', { actions: ['MEETUP_NO', 'MEETUP_YES'] });
  refs.set('MEET_UP_QUESTION_SEND_TO_SELLER', { actions: ['MEETUP_NO', 'MEETUP_YES'] });
  refs.set('MEET_UP_NO_SEND_TO_BUYER', { actions: ['MEETUP_YES'] });
  refs.set('MEET_UP_YES_SEND_TO_BUYER', { actions: ['GO_TO_DONATE'] });
  refs.set('MEET_UP_NO_SEND_TO_SELLER', { actions: ['MEETUP_YES'] });
  refs.set('MEET_UP_YES_SEND_TO_SELLER', { actions: ['GO_TO_DONATE'] });
  refs.set('SHIPMENT_BUYER_CANCELS_SEND_TO_SELLER', { actions: ['SHARE_ITEM_ADDED'] });
  refs.set('SHIPMENT_BUYER_CANCELS_SEND_TO_BUYER', {
    actions: ['DO_NOTHING', 'GO_TO_MARKETPLACE'],
    messengerOnly: true,
  });
  refs.set('USER_PHONE_UPDATED', { actions: ['USER_PHONE_UPDATED'] });
  refs.set('USER_PHONE_UPDATED_RESEND', { actions: ['USER_PHONE_UPDATED'] });
  refs.set('ITEM_ADDED', { actions: ['SHARE_ITEM_ADDED'] });
  refs.set('CAMPAIGN_PUBLISHED', { actions: ['SHARE_CAMPAIGN_PUBLISHED'] });
  refs.set('USER_PROFILE_VERIFIED', { actions: ['GO_TO_ADD_CAMPAIGN'] });
  refs.set('MEET_UP_ABOUT_CONTACT_SEND_TO_BUYER', { actions: ['BUYER_GO_TO_MEETUP'] });
  refs.set('MEET_UP_ABOUT_CONTACT_SEND_TO_SELLER', { actions: ['SELLER_GO_TO_MEETUP'] });
  refs.set('MEET_UP_BUYER_CANCELS_SEND_TO_BUYER', { actions: ['GO_TO_MARKETPLACE'] });
  refs.set('MEET_UP_BUYER_CANCELS_SEND_TO_SELLER', { actions: ['SHARE_ITEM_ADDED'] });
  refs.set('MEET_UP_PROPOSED_SEND_TO_PROPOSER', { actions: [], messengerOnly: true });
  refs.set('MEET_UP_PROPOSED_SEND_TO_RECEIVER', { actions: [], messengerOnly: true });
  refs.set('SHIPMENT_BUYER_CANCELS_SEND_TO_BUYER', { actions: ['GO_TO_MARKETPLACE'] });
  refs.set('USER_TOKEN_RESET', { actions: ['GO_TO_VERIFY_EMAIL'] });
  refs.set('PAYMENT_PROBLEM_PROCESS_SEND_TO_BUYER', { actions: ['GO_TO_BUY_ITEM'] });
  refs.set('OFFER_NEW_SEND_TO_BUYER', { actions: ['CANCEL_OFFER'] });
  refs.set('OFFER_NEW_SEND_TO_SELLER', { actions: ['DECLINE_OFFER', 'ACCEPT_OFFER'] });
  refs.set('OFFER_ACCEPTED_SEND_TO_BUYER', { actions: ['GO_TO_BUY_ITEM'] });
  refs.set('OFFER_DECLINED_SEND_TO_BUYER', { inputType: 'Price', actions: ['SEND_NEW_OFFER'] });
  refs.set('OFFER_ITEM_SOLD_SEND_TO_LOSERS', { actions: ['GO_TO_ITEM'] });
  refs.set('OFFER_ACCEPTED_SEND_TO_SELLER', { actions: [], messengerOnly: true });
  refs.set('ITEM_REMOVED', { actions: ['GO_TO_ADD_ITEM'] });
  refs.set('PAYMENT_SHIPPING_OPTION_CONFIRMED_SEND_TO_SELLER', { actions: ['GO_TO_ORDER_ADDRESS'] });
  refs.set('CAMPAIGN_WITH_CASH_SUPPORTED', { actions: ['SHARE_CAMPAIGN_DONATED'] });
  refs.set('SHIPMENT_ITEM_SHIPPED_TO_SELLER', { actions: ['DOWNLOAD_LABEL'] });
  refs.set('SHIPMENT_ITEM_SHIPPED_TO_BUYER', { actions: ['TRACK'] });
  refs.set('SHIPMENT_INVALID_SELLER_ADDRESS_SEND_TO_SELLER', { actions: ['GO_TO_FAILED_ORDER_ADDRESS'] });
  refs.set('SHIPMENT_INVALID_BUYER_ADDRESS_SEND_TO_BUYER', { actions: ['GO_TO_FAILED_ORDER_ADDRESS_BUYER'] });
  refs.set('SHIPMENT_INVALID_BUYER_ADDRESS_SEND_TO_SELLER', { actions: [], messengerOnly: true });
  refs.set('SHIPMENT_INVALID_OTHER_SEND_TO_BUYER', { actions: [], messengerOnly: true });
  refs.set('SHIPMENT_INVALID_OTHER_SEND_TO_SELLER', { actions: [], messengerOnly: true });
  refs.set('SHIPMENT_SELLER_CANCELS_SEND_TO_SELLER', { actions: ['SHARE_ITEM_ADDED'] });
  refs.set('MEET_UP_SELLER_CANCELS_SEND_TO_SELLER', { actions: ['SHARE_ITEM_ADDED'] });
  refs.set('SHIPMENT_SELLER_CANCELS_SEND_TO_BUYER', { actions: ['GO_TO_MARKETPLACE'] });
  refs.set('MEET_UP_SELLER_CANCELS_SEND_TO_BUYER', { actions: ['GO_TO_MARKETPLACE'] });
  refs.set('ITEM_REPORTED', { actions: ['GO_TO_EDIT_ITEM'] });
  refs.set('SUPPORT_CONVERSATION_RESOLVED', { actions: ['DO_NOTHING', 'CLOSE_SUPPORT_CONVERSATION'] });
  refs.set('SUPPORT_CONVERSATION_OVERTAKE', { actions: [], customWarning: true });
  refs.set('BID_CREATED_TO_OWNER', { actions: ['GO_TO_ITEM'] });
  refs.set('BID_CREATED', { actions: ['GO_TO_ITEM'] });
  refs.set('HIGHEST_BIDDER_WON', { actions: ['GO_TO_TRACKED_ITEM_AS_BUYER'] });
  refs.set('HIGHEST_BIDDER_PAY_NOW', { actions: ['GO_TO_TRACKED_ITEM_AS_BUYER'] });
  refs.set('AUCTION_ENDED', { actions: ['GO_TO_ITEM'] });
  refs.set('AUCTION_ENDED_NO_BIDS', { actions: ['GO_TO_ITEM'] });
  refs.set('AUCTION_ITEM_DELETED', { actions: [], messengerOnly: true });
  refs.set('NEW_AUCTION_WINNER_TO_OWNER', { actions: ['GO_TO_ITEM'] });
  refs.set('HIGHEST_BIDDER_NO_PAYMENT', { actions: ['GO_TO_ITEM'] });
  refs.set('NEW_AUCTION_WINNER', { actions: ['GO_TO_TRACKED_ITEM_AS_BUYER'] });
  refs.set('AUCTION_END_CHANGE', { actions: ['GO_TO_ITEM'] });
  refs.set('REFUND_REQUESTED_SEND_TO_SELLER', { actions: [], messengerOnly: true });
  refs.set('REFUND_REJECTED_SEND_TO_BUYER', { actions: [], messengerOnly: true });
  refs.set('REFUND_REJECTED_SEND_TO_SELLER', { actions: [], messengerOnly: true });
  refs.set('PURCHASE_SUPPORT_CANCELS_SEND_TO_BUYER', { actions: ['GO_TO_MARKETPLACE'] });
  refs.set('PURCHASE_SUPPORT_CANCELS_SEND_TO_SELLER', { actions: [], messengerOnly: true });
  refs.set('DONATION_SUPPORT_CANCELS_SEND_TO_PAYER', { actions: [], messengerOnly: true });
  refs.set('PAYMENT_SHIPPING_OPTION_CONFIRMED_ADDRESS_SET_SEND_TO_SELLER', { actions: [], messengerOnly: true });
  refs.set('PAYMENT_SHIPPING_OPTION_CONFIRMED_SEND_TO_BUYER', { actions: [], messengerOnly: true });
  refs.set('PRIVATE_MESSAGE_TEXT', { actions: [] });
  refs.set('PRIVATE_MESSAGE_PHOTO', { actions: [] });
  refs.set('ITEM_UPDATED', { actions: [], messengerOnly: true });
  refs.set('CAMPAIGN_UPDATED', { actions: [], messengerOnly: true });
  refs.set('OFFER_CANCELED_SEND_TO_SELLER', { actions: [], messengerOnly: true });
  refs.set('CAMPAIGN_HIDDEN_TO_SUPPORTER', { actions: [], messengerOnly: true });
  refs.set('CAMPAIGN_HIDDEN_TO_OWNER', { actions: [], messengerOnly: true });
  refs.set('ITEM_HIDDEN', { actions: [], messengerOnly: true });
  refs.set('USER_CONFIRMED', { actions: [], messengerOnly: true });
  refs.set('USER_EMAIL_CONFIRMED', { actions: [], messengerOnly: true });
  refs.set('USER_TOKEN_RESET', { actions: [], messengerOnly: true });
  refs.set('USER_PHONE_CONFIRMED', { actions: [], messengerOnly: true });
  refs.set('USER_PASSWORD_CHANGED', { actions: [], messengerOnly: true });
  refs.set('OFFER_DECLINED_SEND_TO_SELLER', { actions: [], messengerOnly: true });
  refs.set('DEFAULT', { actions: ['GET_APP'] });
  refs.set('TRANSACTION_VERIFICATION_SEND_TO_BUYER', { actions: [] });
  return refs;
};

export const createTitle = (notificationPayloadType: string, attributes?: Map<string, string>): string => {
  return i18n.t(`notifications:messenger.${notificationPayloadType}.title`, { ...attributes });
};

export const createDescription = (notificationPayloadType: string, attributes?: Map<string, string>): string => {
  return i18n.t(`notifications:messenger.${notificationPayloadType}.body`, { ...attributes });
};

export const createButtonLabel = (notificationPayloadType: string, buttonIndex: number): string => {
  if (buttonIndex === 0) {
    return i18n.t(`notifications:messenger.${notificationPayloadType}.buttons.firstButton`);
  } else if (buttonIndex === 1) {
    return i18n.t(`notifications:messenger.${notificationPayloadType}.buttons.secondButton`);
  }
  return '';
};

export const createPlaceholder = (notificationPayloadType: string): string => {
  return i18n.t(`notifications:messenger.${notificationPayloadType}.textInput`);
};
