import { useTranslation } from 'react-i18next';

import FormStepWrapper from 'components/form/FormStepWrapper';
import ChoicePicker, { ChoiceOption } from 'components/inputs/ChoicePicker';
import DateRangeField from 'components/inputs/DateRangeField';
import { Text1 } from 'components/typography/Texts';
import { useAddCampaign } from 'features/add-campaign/context/AddCampaignProvider';
import { variantOfTimes } from 'features/add-campaign/form/utils/variantOfTime';
import { currentDate } from 'utils/backendDateParser';

const SetTimeline = () => {
  const { t } = useTranslation('addCampaign');
  const { formData, setValues, errors } = useAddCampaign();
  const isValid = !errors.timelineFrom && !errors.timelineTo;

  const handleTimelineChange = (valueFrom?: Date, valueTo?: Date) => {
    setValues(prevValues => ({
      ...prevValues,
      timelineFrom: valueFrom,
      timelineTo: valueTo,
    }));
  };

  const options: ChoiceOption[] =
    variantOfTimes?.map(variantOfTime => {
      return {
        key: variantOfTime.id,
        name: variantOfTime.text,
        isActive: variantOfTime.isSelected(formData.timelineFrom, formData.timelineTo),
      };
    }) || [];

  const handleVariantSelection = (key: string) => {
    const variantOfTime = variantOfTimes.find(variant => variant.id === key);
    handleTimelineChange(variantOfTime?.getDateFrom(), variantOfTime?.getDateTo());
  };

  return (
    <FormStepWrapper header={t('timeline.header')} description={t('timeline.description')} isValid={isValid}>
      <ChoicePicker options={options} onSelect={handleVariantSelection} />
      <Text1>{t('timeline.or-custom-date')}</Text1>
      <DateRangeField
        valueFrom={formData.timelineFrom}
        valueTo={formData.timelineTo}
        onChange={handleTimelineChange}
        minDate={currentDate()}
        placeholder={t('timeline.custom-date-placeholder')}
        testId={'timeline-input'}
      />
    </FormStepWrapper>
  );
};

export default SetTimeline;
